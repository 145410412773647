import React, { Suspense, useEffect, useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logout, login } from "./utils/api";
import { LayoutSplashScreen } from "../_metronic/layout";

import permissions from "./../app/utils/permissions";
import { isTFB } from "./utils/utilFunctions";

import Logout from "./Auth/Logout";
import Notifications from "./pages/Notifications/Notifications";
import { DashboardPage } from "./pages/DashboardPage";

import ClubProfile from "./pages/Club/ClubProfile/ClubProfile";
import Team from "./pages/Club/Team.js";
import MedicalCertificates from "./pages/Club/MedicalCertificates/MedicalCertificates";
import StaffProfile from "./pages/Club/StaffProfile/StaffProfile";
import PlayerProfile from "./pages/Club/PlayerProfile/PlayerProfile";
import PlayerGallery from "./pages/SiteAdmin/Photos/PlayerGallery";
import Calendar from "./components/widgets/Calendar/Calendar";
import MatchesProvider from "./pages/Club/Matches/MatchesContext";
import MatchDetails from "./pages/Club/Matches/match/MatchDetails";
import MatchDetailsInStat from "./components/data-providers/football-api/match/MatchDetails";
import MatchCostsEdit from "./pages/Financial/MatchCosts/MatchCostsEdit";
import Competitions from "./pages/Club/Matches/Competitions/Competitions";
import CompetitionPage from "./pages/Club/Matches/Competitions/CompetitionPage";
import CompetitionInfoPage from "./pages/Club/Matches/Competitions/CompetitionInfoPage";
import PrizesPage from "./pages/Club/Matches/Competitions/PrizesPage";
import VenueManagement from "./pages/Club/VenueManagement/VenueManagement";
import CardsManagement from "./pages/Club/ClubProfile/CardsManagement/CardsManagement.js";
import Goals from "./pages/Statistics/Goals";
import FRFLists from "./pages/Club/ClubProfile/FRFLists/FRFLists";
import EnrolmentRequests from "./pages/Club/EnrolmentRequests/EnrolmentRequests";

import PlanTraining from "./pages/Training/PlanTraining/PlanTraining.js";
import AddTraining from "./pages/Training/PlanTraining/AddTraining.js";
import EditTraining from "./pages/Training/PlanTraining/EditTraining.js";
import TrainingHistory from "./pages/Training/TrainingHistory/TrainingHistory.js";
import TrainingPage from "./pages/Training/TrainingPage/TrainingPage";
import TrainingDetailsPage from "./pages/Training/TrainingDetailsPage/TrainingDetailsPage";
import RpeTraining from "./pages/Training/Rpe/RpeTraining";
import WeightControl from "./pages/Training/WeightControl/WeightControl";
import TrainingSections from "./pages/Training/Sections/TrainingSections.js";
import PresenceReport from "./pages/Training/Presence/PresenceReport.js";

import Reports from "./pages/Reports/Reports";
import AddReport from "./pages/Reports/AddReport";
import ReportPage from "./pages/Reports/ReportPage";
import PhysicalReportEdit from "./pages/Reports/PhysicalReportEdit";
import Evaluation from "./pages/Reports/Evaluation/Evaluation.js";
import AddEvaluation from "./pages/Reports/Evaluation/AddEvaluation";
import EditEvaluation from "./pages/Reports/Evaluation/EditEvaluation";
import EvaluationStats from "./pages/Reports/Evaluation/EvaluationStats";

import PaymentRequest from "./pages/Financial/PaymentRequest/PaymentRequest";
import PaymentRequestDetails from "./pages/Financial/PaymentRequest/PaymentRequestDetails";
import Costs from "./pages/Financial/Costs/Costs";
import OnlinePayments from "./pages/Financial/OnlinePaymentsList/OnlinePayments";
import NecessityReport from "./pages/Financial/NecessityReport/NecessityReport";
import ContExtras from "./pages/Financial/ContExtras/ContExtras";
import Receipt from "./pages/Financial/Receipt/Receipt";
import BonusesMonitoring from "./pages/Financial/BonusesMonitoring/BonusesMonitoring.js";
import IncomeOutcome from "./pages/Financial/Cashflow/IncomeOutcome";
import FinancialReport from "./pages/Financial/FinancialReport/FinancialReport";

import Injuries from "./pages/Medical/Injuries/Injuries";

import Documents from "./pages/Documents/Documents.js";
import PlayerContracts from "./pages/Documents/PlayerContracts";

import FansList from "./pages/Fans/FansList/FansList";
import FanProfile from "./pages/Fans/FanProfile/FanProfile";
import ImportCsv from "./pages/Fans/ImportCSV/ImportCsv";
import Campaigns from "./pages/Fans/Campaigns/Campaigns";
import FansDashboard from "./pages/Fans/Dashboard/FansDashboard";
import TaxRedirectors from "./pages/Fans/TaxRedirectors";

import VideoGallery from "./pages/VideoGallery/VideoGallery.js";
import VideoDetails from "./pages/VideoGallery/VideoDetails/VideoDetails";

import NewsPage from "./pages/SiteAdmin/News/NewsPage/NewsPage";
import AddNews from "./pages/SiteAdmin/News/AddNews/AddNews";
import { default as AddNewsMobile } from "./pages/MobileAppAdmin/News/AddNews.js";
import { default as EditNewsMobile } from "./pages/MobileAppAdmin/News/EditNews.js";
import EditNews from "./pages/SiteAdmin/News/EditNews/EditNews";
import AboutUs from "./pages/SiteAdmin/AboutUs/AboutUs";
import SiteSponsors from "./pages/SiteAdmin/Sponsors/SiteSponsors";
import Photos from "./pages/SiteAdmin/Photos/Photos";
import SiteContact from "./pages/SiteAdmin/Contact/SiteContact";
import SocialMedia from "./pages/SiteAdmin/SocialMedia/SocialMedia";
import WebsiteSettings from "./pages/SiteAdmin/WebsiteSettings/WebsiteSettings";
import TeamsSettings from "./pages/SiteAdmin/TeamsSettings/TeamsSettings";
import StaffSettings from "./pages/SiteAdmin/StaffSettings/StaffSettings";
import PrivacyPolicy from "./pages/SiteAdmin/PrivacyPolicy/PrivacyPolicy";

import MobileUser from "./pages/MobileAppAdmin/UserList/MobileUser";
import UserNotifications from "./pages/MobileAppAdmin/UserNotifications/UserNotifications";

import Products from "./pages/Shop/Products/Products";
import Orders from "./pages/Shop/Orders/Orders";
import Categories from "./pages/Shop/Categories/Categories";
import Sizes from "./pages/Shop/Sizes/Sizes";

import UsersManagement from "./pages/UsersManagement/UsersManagement";

import InactivePage from "./pages/InactivePage";
import StaffContract from "./pages/Documents/StaffContract/StaffContract";
import ImportPlayer from "./pages/Club/ClubProfile/ImportPlayer/ImportPlayer";
import NewsList from "./pages/MobileAppAdmin/News/NewsList";
import EmailMobileUserActivation from "./pages/MobileAppAdmin/UserList/EmailMobileUserActivation";
import DocumentToSign from "./pages/MobileAppAdmin/DocumentToSign/DocumentToSign";
import AddDocumentToSign from "./pages/MobileAppAdmin/DocumentToSign/AddDocumentToSign";
import ViewDocumentToSign from "./pages/MobileAppAdmin/DocumentToSign/ViewDocumentToSign";
import AllPlayersPayments from "./pages/Financial/AllPlayersPayments/AllPlayersPayments";

import PlayerSearch from "./pages/Scouting/PlayerSearch";
import MyList from "./pages/Scouting/MyList";
import PlayersStatistics from "./pages/Scouting/Statistics/PlayersStatistics";
import ShortlistPlayers from "./pages/Scouting/ShortlistPlayers";
import FinancialJustifyingDocument from "./pages/Financial/FinancialDocument/FinancialJustifyingDocument";
import ViewJustifyingDocument from "./pages/Financial/FinancialDocument/ViewJustifyingDocument";
import Press from "./pages/Club/Press/Press";
import { PressProvider } from "./pages/Club/Press/PressContext";
import { Matches } from "./pages/Club/Matches/Matches";

export function BasePage(props) {
  const { user_permissions } = props;

  const [defaultRoute, setDefaultRoute] = useState("");
  useEffect(() => {
    let redirect_route = "";
    for (let permission in user_permissions) {
      switch (permission) {
        case permissions?.[permissions.CLUB]:
          redirect_route = `/${
            user_permissions?.[permissions.CLUB]?.[permissions.TEAM]
              ? "club"
              : user_permissions?.[permissions.CLUB]?.[permissions.CALENDAR]
              ? "club/calendar"
              : user_permissions?.[permissions.CLUB]?.[permissions.MATCH]
              ? "club/matches"
              : user_permissions?.[permissions.CLUB]?.[permissions.COMPETITION]
              ? "club/competitions"
              : user_permissions?.[permissions.CLUB]?.[permissions.VENUE]
              ? "club/venue-management"
              : user_permissions?.[permissions.CLUB]?.[
                  permissions.CARDS_MANAGEMENT
                ]
              ? "club/cards-management"
              : user_permissions?.[permissions.CLUB]?.[permissions.GOALS_STATS]
              ? "club/stats/goals"
              : user_permissions?.[permissions.CLUB]?.[permissions.PRESS]
              ? "club/press"
              : user_permissions?.[permissions.CLUB]?.[permissions.FRF_LIST]
              ? "club/frf-lists"
              : user_permissions?.[permissions.CLUB]?.[
                  permissions.ENROLMENT_REQUEST
                ]
              ? "club/enrolment-requests"
              : ""
          }`;
          break;
        case permissions?.[permissions.TRAINING]:
          redirect_route = `/training${
            user_permissions?.[permissions.TRAINING]?.[
              permissions.TRAINING_HISTORY
            ]
              ? "/history"
              : user_permissions?.[permissions.TRAINING]?.[
                  permissions.TRAINING_PLANING
                ]
              ? "/plan"
              : user_permissions?.[permissions.TRAINING]?.[
                  permissions.WEIGHT_CONTROL
                ]
              ? "/weight"
              : user_permissions?.[permissions.TRAINING]?.[
                  permissions.TRAINING_SECTION
                ]
              ? "/sections"
              : user_permissions?.[permissions.TRAINING]?.[
                  permissions.TRAINING_PRESENCE_REPORT
                ]
              ? "/report-presence"
              : ""
          }`;
          break;
        case permissions?.[permissions.REPORT]:
          redirect_route = `/reports${
            user_permissions?.[permissions.REPORT]?.[permissions.EVALUATION]
              ? "/evaluation"
              : user_permissions?.[permissions.REPORT]?.[
                  permissions.PHYSICAL_TEST
                ]
              ? "/report"
              : ""
          }`;
          break;
        case permissions?.[permissions.FINANCIAL]:
          redirect_route = `/financial${
            user_permissions?.[permissions.FINANCIAL]?.[
              permissions.PAYMENT_MANAGEMENT
            ]
              ? "/all-players-payments"
              : user_permissions?.[permissions.FINANCIAL]?.[
                  permissions.FINANCIAL_REPORT
                ]
              ? "/financial-report"
              : user_permissions?.[permissions.FINANCIAL]?.[
                  permissions.PAYMENT_REQUEST
                ]
              ? "/payment-request"
              : user_permissions?.[permissions.FINANCIAL]?.[permissions.COST]
              ? "/costs"
              : user_permissions?.[permissions.FINANCIAL]?.[
                  permissions.ONLINE_PAYMENT
                ]
              ? "/online-payments"
              : user_permissions?.[permissions.FINANCIAL]?.[
                  permissions.NECCESSITY_REPORT
                ]
              ? "/necessity-reports"
              : user_permissions?.[permissions.FINANCIAL]?.[
                  permissions.JUSTIFYING_DOCUMENT
                ]
              ? "/justifying-document"
              : user_permissions?.[permissions.FINANCIAL]?.[
                  permissions.IMPORT_FROM_STATEMENT
                ]
              ? "/cont-extras"
              : user_permissions?.[permissions.FINANCIAL]?.[permissions.RECEIPT]
              ? "/receipt"
              : user_permissions?.[permissions.FINANCIAL]?.[
                  permissions.BONUS_MONITORING
                ]
              ? "/bonuses-monitoring"
              : user_permissions?.[permissions.FINANCIAL]?.[permissions.BUDGET]
              ? "/cashflow"
              : ""
          }`;
          break;
        case permissions?.[permissions.MEDICAL]:
          redirect_route = `/medical${
            user_permissions?.[permissions.MEDICAL]?.[permissions.INJURY]
              ? "/injuries"
              : ""
          }`;
          break;
        case permissions?.[permissions.DOCUMENT]:
          redirect_route = `/${
            user_permissions?.[permissions.DOCUMENT]?.[permissions.DOCUMENT]
              ? "documents"
              : user_permissions?.[permissions.DOCUMENT]?.[
                  permissions.CONTRACT_PLAYER
                ]
              ? "documents/player-contracts"
              : user_permissions?.[permissions.DOCUMENT]?.[
                  permissions.CONTRACT_STAFF
                ]
              ? "documents/staff-contracts"
              : ""
          }`;
          break;
        case permissions?.[permissions.FAN]:
          redirect_route = `/fans${
            user_permissions?.[permissions.FAN]?.[permissions.FANS_LIST]
              ? "/list"
              : user_permissions?.[permissions.FAN]?.[permissions.FANS_IMPORT]
              ? "/import"
              : user_permissions?.[permissions.FAN]?.[permissions.FANS_CAMPAIGN]
              ? "/campaigns"
              : user_permissions?.[permissions.FAN]?.[
                  permissions.FANS_SOCIAL_MEDIA
                ]
              ? "/social_dashboard"
              : user_permissions?.[permissions.FAN]?.[
                  permissions.TAX_REDIRECTION
                ]
              ? "/tax-redirectors"
              : ""
          }`;
          break;
        case permissions?.[permissions.VIDEOGALLERY]:
          redirect_route = `/${
            user_permissions?.[permissions.VIDEOGALLERY]?.[permissions.VIDEO]
              ? "video-gallery"
              : ""
          }`;
          break;
        case permissions?.[permissions.SITE]:
          redirect_route = `/site${
            user_permissions?.[permissions.SITE]?.[permissions.NEWS]
              ? "/news"
              : user_permissions?.[permissions.SITE]?.[permissions.ABOUT_US]
              ? "/about_us"
              : user_permissions?.[permissions.SITE]?.[permissions.SPONSOR]
              ? "/sponsors"
              : user_permissions?.[permissions.SITE]?.[
                  permissions.PHOTO_VIDEO_GALLERY
                ]
              ? "/photos"
              : user_permissions?.[permissions.SITE]?.[permissions.CONTACT]
              ? "/contact"
              : user_permissions?.[permissions.SITE]?.[
                  permissions.SOCIAL_MEDIA_CONNECT
                ]
              ? "/social-media"
              : user_permissions?.[permissions.SITE]?.[
                  permissions.WEBSITE_SETTINGS
                ]
              ? "/website-settings"
              : user_permissions?.[permissions.SITE]?.[
                  permissions.TEAMS_SETTINGS
                ]
              ? "/teams-settings"
              : user_permissions?.[permissions.SITE]?.[
                  permissions.STAFF_SETTINGS
                ]
              ? "/staff-settings"
              : user_permissions?.[permissions.SITE]?.[
                  permissions.PRIVACY_POLICY
                ]
              ? "/privacy-policy-site"
              : ""
          }`;
          break;
        case permissions?.[permissions.PARENTS_APP]:
          redirect_route = `/application${
            user_permissions?.[permissions.PARENTS_APP]?.[
              permissions.PARENT_USER
            ]
              ? "/users"
              : user_permissions?.[permissions.PARENTS_APP]?.[
                  permissions.NOTIFICATE_USER
                ]
              ? "/users-notifications"
              : user_permissions?.[permissions.PARENTS_APP]?.[permissions.NEWS]
              ? "/application/news"
              : ""
          }`;
          break;
        case permissions?.[permissions.SHOP]:
          redirect_route = `/shop${
            user_permissions?.[permissions.SHOP]?.[permissions.PRODUCT]
              ? "/products"
              : user_permissions?.[permissions.SHOP]?.[permissions.ORDER]
              ? "/orders"
              : user_permissions?.[permissions.SHOP]?.[permissions.CATEGORY]
              ? "/categories"
              : user_permissions?.[permissions.SHOP]?.[permissions.SIZE]
              ? "/sizes"
              : ""
          }`;
          break;
        case permissions?.[permissions.CLUB_ADMIN]:
          redirect_route = `/${
            user_permissions?.[permissions.CLUB_ADMIN]?.[permissions.USER_LIST]
              ? "users-management"
              : ""
          }`;
          break;
        default:
          break;
      }
      if (redirect_route != "") {
        break;
      }
    }
    setDefaultRoute(redirect_route);
  }, [user_permissions]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* ################## LOGOUT ##################*/}

        <Route exact path="/logout" component={Logout} />

        {/* ################## NOTIFICATIONS PAGE ##################*/}

        <Route exact path="/notifications" component={Notifications} />

        {/* ################## DASHBOARD ##################*/}

        {user_permissions?.[permissions.DASHBOARD] && (
          <Route exact path="/dashboard" component={DashboardPage} />
        )}
        {user_permissions?.[permissions.DASHBOARD] && (
          <Redirect exact from="/" to="/dashboard" />
        )}

        {/* ################## CLUB ##################*/}

        {user_permissions?.[permissions.CLUB]?.[permissions.TEAM] && (
          <Route exact path="/club" component={ClubProfile} />
        )}
        {user_permissions?.[permissions.CLUB]?.[permissions.TEAM] && (
          <Route exact path="/club/team/:id" component={Team} />
        )}
        {user_permissions?.[permissions.CLUB]?.[permissions.TEAM] && (
          <Route
            exact
            path="/club/team/import-player/:id"
            component={ImportPlayer}
          />
        )}
        {isTFB() &&
          user_permissions?.[permissions.CLUB]?.[permissions.TEAM] && (
            <Route
              exact
              path="/club/medical-certificates/:id"
              component={MedicalCertificates}
            />
          )}
        {isTFB() &&
          user_permissions?.[permissions.CLUB]?.[permissions.TEAM] && (
            <Route exact path="/club/payments/:id" component={AllPlayersPayments} />
          )}
        {user_permissions?.[permissions.CLUB]?.[permissions.TEAM] && (
          <Route exact path="/club/staff/:id" component={StaffProfile} />
        )}
        {user_permissions?.[permissions.CLUB]?.[permissions.TEAM] && (
          <Route exact path="/club/player/:id" component={PlayerProfile} />
        )}
        {user_permissions?.[permissions.CLUB]?.[permissions.TEAM] && (
          <Route
            exact
            path="/club/player-gallery/:id"
            component={PlayerGallery}
          />
        )}

        {user_permissions?.[permissions.CLUB]?.[permissions.CALENDAR] && (
          <Route exact path="/club/calendar" component={Calendar} />
        )}

        {user_permissions?.[permissions.CLUB]?.[permissions.MATCH] && (
          <Route exact path="/club/matches">
            <MatchesProvider>
              <Matches />
            </MatchesProvider>
          </Route>
        )}
        {user_permissions?.[permissions.CLUB]?.[permissions.MATCH] && (
          <Route exact path="/club/match/:id" component={MatchDetails} />
        )}
        {user_permissions?.[permissions.CLUB]?.[permissions.MATCH] && (
          <Route
            exact
            path="/club/match/instat/:id"
            component={MatchDetailsInStat}
          />
        )}
        {user_permissions?.[permissions.CLUB]?.[permissions.MATCH]?.[
          permissions.MATCH_COSTS
        ] && (
          <Route
            exact
            path="/club/match-costs/edit/:id"
            component={MatchCostsEdit}
          />
        )}

        {user_permissions?.[permissions.CLUB]?.[permissions.COMPETITION] && (
          <Route exact path="/club/competitions" component={Competitions} />
        )}
        {user_permissions?.[permissions.CLUB]?.[permissions.COMPETITION] && (
          <Route
            exact
            path="/club/competitions/:id"
            component={CompetitionPage}
          />
        )}
        {user_permissions?.[permissions.CLUB]?.[permissions.COMPETITION] && (
          <Route
            exact
            path="/club/competitions/info/:id"
            component={CompetitionInfoPage}
          />
        )}
        {user_permissions?.[permissions.CLUB]?.[permissions.COMPETITION] && (
          <Route
            exact
            path="/club/competitions/prizes/:id"
            component={PrizesPage}
          />
        )}
        {isTFB() &&
          user_permissions?.[permissions.CLUB]?.[permissions.VENUE] && (
            <Route
              exact
              path="/club/venue-management"
              component={VenueManagement}
            />
          )}
        {isTFB() && (
          <Route exact path="/club/press">
            <PressProvider>
              <Press />
            </PressProvider>
          </Route>
        )}

        {isTFB() &&
          user_permissions?.[permissions.CLUB]?.[
            permissions.CARDS_MANAGEMENT
          ] && (
            <Route
              exact
              path="/club/cards-management"
              component={CardsManagement}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.CLUB]?.[permissions.GOALS_STATS] && (
            <Route exact path="/club/stats/goals" component={Goals} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.CLUB]?.[permissions.FRF_LIST] && (
            <Route exact path="/club/frf-lists" component={FRFLists} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.CLUB]?.[
            permissions.ENROLMENT_REQUEST
          ] && (
            <Route
              exact
              path="/club/enrolment-requests"
              component={EnrolmentRequests}
            />
          )}

        {/* ################## TRAINING ##################*/}

        {isTFB() &&
          user_permissions?.[permissions.TRAINING]?.[
            permissions.TRAINING_PLANING
          ] && <Route exact path="/training/plan" component={PlanTraining} />}
        {isTFB() &&
          user_permissions?.[permissions.TRAINING]?.[
            permissions.TRAINING_PLANING
          ] && (
            <Route exact path="/training/plan/add" component={AddTraining} />
          )}
        {isTFB() &&
          user_permissions?.[permissions.TRAINING]?.[
            permissions.TRAINING_PLANING
          ] && (
            <Route
              exact
              path="/training/plan/edit/:id"
              component={EditTraining}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.TRAINING]?.[
            permissions.TRAINING_HISTORY
          ] && (
            <Route exact path="/training/history" component={TrainingHistory} />
          )}
        {isTFB() &&
          user_permissions?.[permissions.TRAINING]?.[
            permissions.TRAINING_HISTORY
          ] && (
            <Route
              exact
              path="/training/history/:id"
              component={TrainingPage}
            />
          )}
        {isTFB() &&
          user_permissions?.[permissions.TRAINING]?.[
            permissions.TRAINING_HISTORY
          ] && (
            <Route
              exact
              path="/training/history/details/:id"
              component={TrainingDetailsPage}
            />
          )}
        {isTFB() &&
          user_permissions?.[permissions.TRAINING]?.[
            permissions.TRAINING_HISTORY
          ] && (
            <Route
              exact
              path="/training/rpe/:id"
              render={(props) => <RpeTraining {...props} />}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.TRAINING]?.[
            permissions.WEIGHT_CONTROL
          ] && (
            <Route exact path="/training/weight" component={WeightControl} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.TRAINING]?.[
            permissions.TRAINING_SECTION
          ] && (
            <Route
              exact
              path="/training/sections"
              component={TrainingSections}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.TRAINING]?.[
            permissions.TRAINING_PRESENCE_REPORT
          ] && (
            <Route
              exact
              path="/training/report-presence"
              component={PresenceReport}
            />
          )}

        {/* ################## REPORT ##################*/}
        {isTFB() &&
          user_permissions?.[permissions.REPORT]?.[permissions.EVALUATION] && (
            <Route exact path="/reports/evaluation" component={Evaluation} />
          )}
        {isTFB() &&
          user_permissions?.[permissions.REPORT]?.[permissions.EVALUATION] && (
            <Route
              exact
              path="/reports/evaluation/add"
              component={AddEvaluation}
            />
          )}
        {isTFB() &&
          user_permissions?.[permissions.REPORT]?.[permissions.EVALUATION] && (
            <Route
              exact
              path="/reports/evaluation/:id"
              component={EvaluationStats}
            />
          )}
        {isTFB() &&
          user_permissions?.[permissions.REPORT]?.[permissions.EVALUATION] && (
            <Route
              exact
              path="/reports/evaluation/edit/:id"
              component={EditEvaluation}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.REPORT]?.[
            permissions.PHYSICAL_TEST
          ] && <Route exact path="/reports/report" component={Reports} />}
        {isTFB() &&
          user_permissions?.[permissions.REPORT]?.[
            permissions.PHYSICAL_TEST
          ] && <Route exact path="/reports/add" component={AddReport} />}
        {isTFB() &&
          user_permissions?.[permissions.REPORT]?.[
            permissions.PHYSICAL_TEST
          ] && <Route exact path="/reports/:type/:id" component={ReportPage} />}
        {isTFB() &&
          user_permissions?.[permissions.REPORT]?.[
            permissions.PHYSICAL_TEST
          ] && (
            <Route
              exact
              path="/reports/physical/edit/:id-:id_team"
              component={PhysicalReportEdit}
            />
          )}

        {/* ################## FINANCIAL ##################*/}

        {isTFB() &&
          user_permissions?.[permissions.FINANCIAL]?.[
            permissions.PAYMENT_REQUEST
          ] && (
            <Route
              exact
              path="/financial/payment-request"
              component={PaymentRequest}
            />
          )}
        {isTFB() &&
          user_permissions?.[permissions.FINANCIAL]?.[
            permissions.PAYMENT_REQUEST
          ] && (
            <Route
              exact
              path="/financial/payment-request-details/:id"
              component={PaymentRequestDetails}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.FINANCIAL]?.[permissions.COST] && (
            <Route exact path="/financial/costs" component={Costs} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.FINANCIAL]?.[
            permissions.ONLINE_PAYMENT
          ] && (
            <Route
              exact
              path="/financial/online-payments"
              component={OnlinePayments}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.FINANCIAL]?.[
            permissions.NECCESSITY_REPORT
          ] && (
            <Route
              exact
              path="/financial/necessity-reports"
              component={NecessityReport}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.FINANCIAL]?.[
            permissions.JUSTIFYING_DOCUMENT
          ] && (
            <Route
              exact
              path="/financial/justifying-document"
              component={FinancialJustifyingDocument}
            />
          )}
        {isTFB() &&
          user_permissions?.[permissions.FINANCIAL]?.[
            permissions.JUSTIFYING_DOCUMENT
          ] && (
            <Route
              exact
              path="/financial/justifying-document/:id"
              component={ViewJustifyingDocument}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.FINANCIAL]?.[
            permissions.IMPORT_FROM_STATEMENT
          ] && (
            <Route exact path="/financial/cont-extras" component={ContExtras} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.FINANCIAL]?.[permissions.RECEIPT] && (
            <Route exact path="/financial/receipt" component={Receipt} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.FINANCIAL]?.[
            permissions.BONUS_MONITORING
          ] && (
            <Route
              exact
              path="/financial/bonuses-monitoring"
              component={BonusesMonitoring}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.FINANCIAL]?.[permissions.BUDGET] && (
            <Route exact path="/financial/cashflow" component={IncomeOutcome} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.FINANCIAL]?.[
            permissions.PAYMENT_MANAGEMENT
          ] && (
            <Route
              exact
              path="/financial/all-players-payments"
              component={AllPlayersPayments}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.FINANCIAL]?.[
            permissions.FINANCIAL_REPORT
          ] && (
            <Route
              exact
              path="/financial/financial-report"
              component={FinancialReport}
            />
          )}

        {/* ################## MEDICAL ##################*/}

        {isTFB() &&
          user_permissions?.[permissions.MEDICAL]?.[permissions.INJURY] && (
            <Route exact path="/medical/injuries" component={Injuries} />
          )}

        {/* ################## DOCUMENT ##################*/}

        {isTFB() &&
          user_permissions?.[permissions.DOCUMENT]?.[permissions.DOCUMENT] && (
            <Route exact path="/documents" component={Documents} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.DOCUMENT]?.[
            permissions.CONTRACT_PLAYER
          ] && (
            <Route
              exact
              path="/documents/player-contracts"
              component={PlayerContracts}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.DOCUMENT]?.[
            permissions.CONTRACT_STAFF
          ] && (
            <Route
              exact
              path="/documents/staff-contracts"
              component={StaffContract}
            />
          )}

        {/* ################## FAN ##################*/}

        {isTFB() &&
          user_permissions?.[permissions.FAN]?.[permissions.FANS_LIST] && (
            <Route exact path="/fans/list" component={FansList} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.FAN]?.[permissions.FANS_IMPORT] && (
            <Route exact path="/fans/import" component={ImportCsv} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.FAN]?.[permissions.FANS_CAMPAIGN] && (
            <Route exact path="/fans/campaigns" component={Campaigns} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.FAN]?.[
            permissions.FANS_SOCIAL_MEDIA
          ] && (
            <Route
              exact
              path="/fans/social_dashboard"
              component={FansDashboard}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.FAN]?.[
            permissions.TAX_REDIRECTION
          ] && (
            <Route
              exact
              path="/fans/tax-redirectors"
              component={TaxRedirectors}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.FAN]?.[permissions.FANS_LIST] && (
            <Route exact path="/fans/:id" component={FanProfile} />
          )}

        {/* ################## VIDEOGALLERY ##################*/}

        {user_permissions?.[permissions.VIDEOGALLERY]?.[permissions.VIDEO] && (
          <Route exact path="/video-gallery" component={VideoGallery} />
        )}
        {user_permissions?.[permissions.VIDEOGALLERY]?.[permissions.VIDEO] && (
          <Route exact path="/video-gallery/:id" component={VideoDetails} />
        )}

        {/* ################## SITE ADMIN ##################*/}

        {isTFB() &&
          user_permissions?.[permissions.SITE]?.[permissions.NEWS] && (
            <Route exact path="/site/news" component={NewsPage} />
          )}
        {isTFB() &&
          user_permissions?.[permissions.SITE]?.[permissions.NEWS] && (
            <Route exact path="/site/news/add" component={AddNews} />
          )}
        {isTFB() &&
          user_permissions?.[permissions.SITE]?.[permissions.NEWS] && (
            <Route exact path="/site/news/edit/:id" component={EditNews} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.SITE]?.[permissions.ABOUT_US] && (
            <Route exact path="/site/about_us" component={AboutUs} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.SITE]?.[permissions.SPONSOR] && (
            <Route exact path="/site/sponsors" component={SiteSponsors} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.SITE]?.[
            permissions.PHOTO_VIDEO_GALLERY
          ] && <Route exact path="/site/photos" component={Photos} />}

        {isTFB() &&
          user_permissions?.[permissions.SITE]?.[permissions.CONTACT] && (
            <Route exact path="/site/contact" component={SiteContact} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.SITE]?.[
            permissions.SOCIAL_MEDIA_CONNECT
          ] && (
            <Route exact path="/site/social-media" component={SocialMedia} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.SITE]?.[
            permissions.WEBSITE_SETTINGS
          ] && (
            <Route
              exact
              path="/site/website-settings"
              component={WebsiteSettings}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.SITE]?.[
            permissions.TEAMS_SETTINGS
          ] && (
            <Route
              exact
              path="/site/teams-settings"
              component={TeamsSettings}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.SITE]?.[
            permissions.STAFF_SETTINGS
          ] && (
            <Route
              exact
              path="/site/staff-settings"
              component={StaffSettings}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.SITE]?.[
            permissions.PRIVACY_POLICY
          ] && (
            <Route
              exact
              path="/site/privacy-policy-site"
              component={PrivacyPolicy}
            />
          )}

        {/* ################## PARENTS APP ##################*/}

        {isTFB() &&
          user_permissions?.[permissions.PARENTS_APP]?.[
            permissions.PARENT_USER
          ] && <Route exact path="/application/users" component={MobileUser} />}

        {isTFB() &&
          user_permissions?.[permissions.PARENTS_APP]?.[
            permissions.PARENT_USER
          ] && (
            <Route
              exact
              path="/application/users/activate-user"
              component={EmailMobileUserActivation}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.PARENTS_APP]?.[
            permissions.NOTIFICATE_USER
          ] && (
            <Route
              exact
              path="/application/users-notifications"
              component={UserNotifications}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.PARENTS_APP]?.[
            permissions.MOBILE_APP_NEWS
          ] && <Route exact path="/application/news" component={NewsList} />}

        {isTFB() &&
          user_permissions?.[permissions.PARENTS_APP]?.[
            permissions.MOBILE_APP_NEWS
          ] && (
            <Route
              exact
              path="/application/news/add"
              component={AddNewsMobile}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.PARENTS_APP]?.[
            permissions.MOBILE_APP_NEWS
          ] && (
            <Route
              exact
              path="/application/news/edit/:id"
              component={EditNewsMobile}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.PARENTS_APP]?.[
            permissions.DOC_TO_SIGN
          ] && (
            <Route
              exact
              path="/application/doc-to-sign"
              component={DocumentToSign}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.PARENTS_APP]?.[
            permissions.DOC_TO_SIGN
          ] && (
            <Route
              exact
              path="/application/add-doc-to-sign"
              component={AddDocumentToSign}
            />
          )}

        {isTFB() &&
          user_permissions?.[permissions.PARENTS_APP]?.[
            permissions.DOC_TO_SIGN
          ] && (
            <Route
              exact
              path="/application/doc-to-sign/:id"
              component={ViewDocumentToSign}
            />
          )}

        {/* ################## SHOP ##################*/}

        {isTFB() &&
          user_permissions?.[permissions.SHOP]?.[permissions.PRODUCT] && (
            <Route exact path="/shop/products" component={Products} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.SHOP]?.[permissions.ORDER] && (
            <Route exact path="/shop/orders" component={Orders} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.SHOP]?.[permissions.CATEGORY] && (
            <Route exact path="/shop/categories" component={Categories} />
          )}

        {isTFB() &&
          user_permissions?.[permissions.SHOP]?.[permissions.SIZE] && (
            <Route exact path="/shop/sizes" component={Sizes} />
          )}

        {/* ################## USERS MANAGEMENT ##################*/}

        {user_permissions?.[permissions.CLUB_ADMIN]?.[
          permissions.USER_LIST
        ] && (
          <Route exact path="/users-management" component={UsersManagement} />
        )}

        {/* ################## NOT FOUND PAGE ##################*/}

        <Route exact path="/404" component={InactivePage} />

        <Route path="/scouting/shortlists" component={MyList} />
        <Route path="/scouting/shortlists/:id" component={ShortlistPlayers} />
        <Route path="/scouting/players" component={PlayerSearch} />
        <Route
          path="/scouting/players-statistics"
          component={PlayersStatistics}
        />

        {/* ################## REDIRECT IF AUTHENTIFICATED ##################*/}

        <Redirect from="/auth" to="/" />

        {/* ################## REDIRECT TO FIRST ENABLE MODULE WHEN NO ACCESS FOR DASHBOARD ##################*/}

        {!user_permissions?.[permissions.DASHBOARD] && (
          <Redirect to={defaultRoute} />
        )}

        {/* ################## REDIRECT TO NOT FOUND IF NO PAGE MATCHES ##################*/}

        <Redirect to="/404" />
      </Switch>
    </Suspense>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
      login,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    user: auth.user || {},
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(BasePage);
