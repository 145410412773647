import React from "react";
import { Avatar, makeStyles } from "@material-ui/core";

import { TPlayerWithAvatar } from "../types";
import styles from "./TFBPlayerWithAvatar.module.scss";
import TFBTeamLabel from "../TFBTeamLabel/TFBTeamLabel";

const TFBPlayerWithAvatar = ({
  className,
  style,
  avatarUrl,
  playerName,
  matricol,
  reason,
  teams,
  size,
  onClick,
  showAvatar = true,
}: TPlayerWithAvatar) => {
  const classes = makeStyles({
    root: {
      "& .MuiAvatar-img": {
        height: "initial !important",
      },
      ...(size && { width: size, height: size }),
    },
  });

  return (
    <div
      className={`${styles.playerAvatarWrapper}  ${className ? className : ""}`}
      style={style}
    >
      {showAvatar &&
        <div style={{ cursor: "pointer" }} onClick={onClick}>
          <Avatar src={avatarUrl} className={classes().root} />
        </div>
      }
      <div>
        <div className={styles.playerName} onClick={onClick}>
          {playerName}
        </div>
        {matricol && (
          <div className={styles.matricol} onClick={onClick}>
            {matricol}
          </div>
        )}
        {teams && (
          <div className="df df-gap-5" style={{ flexDirection: "column" }}>
            {teams.map((e, i) => (
              <TFBTeamLabel
                key={"team-" + i}
                name={e.team_name}
                color={e?.team_color ?? null}
                style={{ minHeight: 11, padding: "0 5px" }}
                textStyle={{ fontSize: 11, whiteSpace: "nowrap" }}
              />
            ))}
          </div>
        )}
        {reason && (
          <div className={styles.reason} onClick={onClick}>
            {reason}
          </div>
        )}
      </div>
    </div>
  );
};

export default TFBPlayerWithAvatar;
