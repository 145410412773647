import React, { useEffect, useMemo, useReducer } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import _, { filter } from "lodash";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import moment from "moment";

import "./Payments.scss";

import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import {
  TFBTableCell,
  TFBTableRow,
  sxStickyColumn,
  sxStickyRow,
} from "../../../components/reusable/useStyles";
import TFBPlayerWithAvatar from "../../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBSearch from "../../../components/design-system/TFBSearch/TFBSearch";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import CircularLoader from "../../../components/reusable/Loader";

import styles from "./AllPlayersPayments.module.scss";
import AddPayment from "./AddPayment";
import EditPayment from "./EditPayment";
import { prepareStringForCompare } from "../../../utils/utilFunctions";
import DebtorPaymentReportPopup from "./DebtorPaymentReportPopup";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";

const AllPlayersPayments = (props) => {
  const history = useHistory();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      players: [],
      year: _.toNumber(moment().format("YYYY")),

      open_add_popup: false,
      add_popup_data: {},

      open_edit_popup: false,
      edit_popup_data: {},

      open_debtor_report_popup: false,

      search_value: "",
      players: [],

      hide_inactive_players: true,
    }
  );

  const { languagePack, token } = props;
  const { lang, id_club } = props.currentUser;
  const { teams } = props.currentUser.user_team_access;
  const { id: id_team } = props?.match?.params;
  const isTeamPage = id_team ? true : false;

  const {
    data: players,
    loading: isPlayersLoading,
    refetch: refetchPlayers,
  } = useAxiosGet(
    `finances/get_players_payments?year=${state.year}&token=${token}${id_team ? `&id_team=${id_team}` : ""}`
  );

  useEffect(() => {
    var filteredArray = filter(players?.list, e => e.inactive == 0);
    if (!state.hide_inactive_players) {
      filteredArray = _.concat(filteredArray, _.filter(players?.list ?? [], (e) => e.inactive == 1))
    }
    if (_.size(state.search_value) > 0) {
      updateState({
        players: filteredArray.filter(
          (v) =>
            prepareStringForCompare(v.player_name).includes(
              prepareStringForCompare(state.search_value)
            ) ||
            prepareStringForCompare(v.serial_number).includes(
              prepareStringForCompare(state.search_value)
            )
        ),
      });
    } else {
      updateState({ players: filteredArray });
    }
  }, [state.hide_inactive_players, state.search_value, players]);

  function handleOpenDebtorReportPopup() {
    updateState({ open_debtor_report_popup: true });
  }
  function handleCloseDebtorReportPopup() {
    updateState({ open_debtor_report_popup: false });
  }

  const openAddPopup = (data) => {
    updateState({ open_add_popup: true, add_popup_data: data });
  };

  const closeAddPopup = () => {
    updateState({ open_add_popup: false, add_popup_data: {} });
  };

  const openEditPopup = (data) => {
    updateState({ open_edit_popup: true, edit_popup_data: data });
  };

  const closeEditPopup = () => {
    updateState({ open_edit_popup: false, edit_popup_data: {} });
  };

  const getMonthPaymentDetails = (monthIndex, monthPayment, firstMYToPay, monthlyTax, condition) => {
    let className = "";
    let value = "";
    let type_label = null;

    const currentDate = moment();
    const paymentDate = moment()
      .set("year", state.year)
      .set("month", monthIndex);
    const firstPaymentDate = moment(firstMYToPay);

    // Check if the month is in the past or current
    if (paymentDate.isSameOrBefore(currentDate, "month")) {
      if (_.size(monthPayment) > 0) {
        value = monthPayment.payment_amount;
        className = "paidMonth";
        type_label = monthPayment.payment_type_label;
      } else {
        value = 0;
        // Check if the month is before or equal to the first payment month and is not the current month
        if (
          (firstMYToPay ? paymentDate.isSameOrAfter(firstPaymentDate, "month") : paymentDate.isBefore(moment(), "month")) &&
          !paymentDate.isSame(currentDate, "month")
        ) {
          if (monthlyTax > 0) {
            value = monthlyTax;
          }
          className = "notPaidMonth";
        } else if (paymentDate.isBefore(firstPaymentDate, "month")) {
          value = "-";
        } else {
          if (monthlyTax > 0) {
            value = monthlyTax;
          }
        }
        if (condition) {
          value = condition.amount;
        }
      }
    } else {
      // Future months
      if (_.size(monthPayment) > 0) {
        value = monthPayment.payment_amount;
        className = "paidMonth";
        type_label = monthPayment.payment_type_label;
      } else {
        value = "-";
        className = "";
        if (monthlyTax > 0) {
          value = monthlyTax;
        }
        if (condition) {
          value = condition.amount;
        }
      }
    }
    if (!(_.size(monthPayment) > 0) && (monthlyTax == 0 || monthlyTax == "" || monthlyTax == null) && !(value > 0)) {
      value = "-";
      className = "";
    }
    return { className, value, type_label };
  };

  const changeYear = (index) => {
    updateState({ year: _.toNumber(state.year) + index });
  };

  const TableCell = (props) => (
    <TFBTableCell {...props} style={{ fontSize: 11, padding: "14px 10px", borderRight: "1px solid #ffffff", ...(props.style ? props.style : {}) }}>{props.children}</TFBTableCell>
  )

  const generatePlayerList = useMemo(
    () => (
      <TableContainer style={{ maxHeight: "80vh" }}>
        <Table>
          <TableHead>
            <TFBTableRow sx={sxStickyRow}>
              <TFBTableCell
                sx={[sxStickyColumn, sxStickyRow]}
                style={{ zIndex: 7 }}
              >
                {languagePack.name}
              </TFBTableCell>
              <TFBTableCell sx={sxStickyRow} align="center"></TFBTableCell>
              <TFBTableCell sx={sxStickyRow} align="center" style={{ backgroundColor: "rgb(255 255 0 / 40%)" }}>
                {languagePack.monthly_tax}
              </TFBTableCell>
              {_.map(_.range(12), (v) => (
                <TFBTableCell
                  key={"month-" + v}
                  sx={sxStickyRow}
                  align="center"
                >
                  {_.capitalize(
                    moment()
                      .set("month", v)
                      .locale(lang ?? "ro")
                      .format("MMMM")
                  )}
                </TFBTableCell>
              ))}
            </TFBTableRow>
          </TableHead>
          <TableBody>
            {_.map(state.players, (row, index) => (
              <TFBTableRow
                key={"player-" + index}
                sx={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                className={`${styles.playerRow} ${row.inactive == 1 ? styles.inactivePlayer : ""}`}
              >
                <TableCell sx={sxStickyColumn}>
                  <TFBPlayerWithAvatar
                    playerName={row?.player_name}
                    avatarUrl={row?.photo}
                    matricol={isTeamPage ? false : row?.serial_number}
                    reason={row?.tax_reason}
                    onClick={() =>
                      history.push(`/club/player/${row.id_player}`)
                    }
                    teams={isTeamPage ? [] : _.map(row?.team?.list, (e) => ({
                      team_name: e.team_name,
                      team_color: e.team_color,
                    }))}
                    showAvatar={!isTeamPage}
                  />
                </TableCell>
                <TableCell align="center" style={{ paddingRight: 20, paddingLeft: 20 }}>
                  {row.inactive == 0 && (
                    <div className="df-g20 df-center-center">
                      <div
                        className="cursor-pointer"
                        onClick={() => openAddPopup(row)}
                      >
                        <TFBIcon name="add" color="green" />
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => openEditPopup(row)}
                      >
                        <TFBIcon name="edit" color="black" />
                      </div>
                    </div>
                  )}
                </TableCell>
                <TableCell align="center" style={{ backgroundColor: "rgb(255 255 0 / 40%)" }}>{row.monthly_tax}</TableCell>
                {_.map(_.range(12), (v) => {
                  const monthIndex = v;
                  const monthPayment =
                    _.find(
                      row.payments?.list ?? [],
                      (e) => e.payment_month - 1 == monthIndex
                    ) ?? {};
                  const paymentData = getMonthPaymentDetails(
                    monthIndex,
                    monthPayment,
                    row.first_month_year_for_payment,
                    row.monthly_tax,
                    row.conditions?.list?.[v + 1] ?? null
                  );

                  return (
                    <TableCell
                      key={"player-" + index + "-month-" + monthIndex}
                      align="center"
                      className={`${styles.monthCell} ${styles?.[paymentData.className]}`}
                    >
                      {paymentData.value}
                      {paymentData.type_label && (
                        <div className={styles.paymentType}>
                          {languagePack.payment_types?.[paymentData.type_label]}
                        </div>
                      )}
                    </TableCell>
                  );
                })}
              </TFBTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ),
    [state.players, languagePack]
  );

  const handleChangeHideInactivePlayers = (e) => {
    updateState({ hide_inactive_players: e.target.checked });
  };

  return (
    <TFBCard>
      <TFBCardHeader title={languagePack.payment_management}>
        <TFBButton size="lg" onClick={handleOpenDebtorReportPopup}>
          {languagePack.generate_debtor_pdf_report}
        </TFBButton>
        <div className="team-payments-navigate-year-container">
          <TFBIconButton
            name="arrow-left"
            color="green"
            onClick={() => changeYear(-1)}
          />
          <div className="year-text">{state.year}</div>
          <TFBIconButton
            name="arrow-right"
            color="green"
            onClick={() => changeYear(1)}
            disabled={state.year == _.toNumber(moment().format("YYYY")) + 1}
          />
        </div>
      </TFBCardHeader>
      <TFBCardBody>
        {!isPlayersLoading && (
          <div className={styles.searchDiv}>
            <TFBSearch
              label={"Cautare"}
              placeholder={"Introdu nume sau matricol jucator..."}
              classNameSearch={styles.searchVideo}
              fluid
              showNoResults={false}
              value={state.search_value}
              onSearchChange={(e) =>
                updateState({ search_value: e.target.value })
              }
            />
            {_.size(state.search_value) > 0 && (
              <div className={styles.selectDiv}>
                <TFBButton
                  color="lightGray"
                  renderIcon={() => <TFBIcon name="cancel" color="gray" />}
                  onClick={() => updateState({ search_value: "" })}
                >
                  {languagePack["cancel_filter"]}
                </TFBButton>
              </div>
            )}
          </div>
        )}

        {!isPlayersLoading && (
          <TFBCheckbox
            label={languagePack.hide_inactiv_players}
            checked={state.hide_inactive_players}
            onChange={handleChangeHideInactivePlayers}
            style={{ marginTop: "20px", marginBottom: "20px" }}
          />
        )}

        {!isPlayersLoading ? (
          _.size(state.players) > 0 ? (
            generatePlayerList
          ) : (
            <TFBPlaceholder text={languagePack.no_players} />
          )
        ) : (
          <CircularLoader />
        )}
      </TFBCardBody>

      {state.open_add_popup && (
        <AddPayment
          openPopup={state.open_add_popup}
          closePopup={closeAddPopup}
          refreshList={refetchPlayers}
          data={state.add_popup_data}
          year={state.year}
          lastNrReceipt={
            players?.last_number_receipt
              ? _.toNumber(players.last_number_receipt)
              : 1
          }
        />
      )}

      {state.open_edit_popup && (
        <EditPayment
          openPopup={state.open_edit_popup}
          closePopup={closeEditPopup}
          refreshList={refetchPlayers}
          data={state.edit_popup_data}
          year={state.year}
          lastNrReceipt={
            players?.last_number_receipt
              ? _.toNumber(players.last_number_receipt)
              : 1
          }
        />
      )}

      {state.open_debtor_report_popup && (
        <DebtorPaymentReportPopup
          clubID={id_club}
          title={languagePack.generate_debtor_pdf_report}
          onCLosePopup={handleCloseDebtorReportPopup}
          id_team={id_team ?? null}
        />
      )}
    </TFBCard>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,

  token: auth?.user?.token,
}))(AllPlayersPayments);
