import React, { Component } from "react";
import { Icon, Checkbox } from "semantic-ui-react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import moment from "moment";
import { bindActionCreators } from "redux";
import _ from "lodash";

import "./AddMatchForm.scss";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import { updateQuickSetup } from "../../../../utils/api";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import {
  isTFB,
  removeAgeGroupFromTeamName,
} from "../../../../utils/utilFunctions";
import ExternTeamLogos from "./ExternTeamLogos";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";

class AddMatchFormDirect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      match_round: "",
      team_home: null,
      team_away: null,
      match_date: null,
      teams: [],
      group: "",
      location: "",
      new_location: "",
      locations: [],
      add_new_location: false,
      competitions: [],
      competition: {
        id: "",
        name: "",
        groups: [],
        sub_comps: [],
        multiple_age_groups: "0",
      },
      sub_comp: {
        id: "",
        name: "",
        groups: [],
      },
      add_match_details: false,
      match_type: "",
      extern_team_type: "",
      extern_team: "",
      extern_team_logos: {},
      extern_team_selected_logo: 0,
      show_extern_team_logos: false,
      loading_logos: false,
      logos_checkbox: false,
      extern_team_uploaded_logo: "",

      minutes_per_time: "45",
      other_match_format: false,
      new_match_format: "",
      selected_match_format: "",
      match_formats: [],

      add_venue_to_mng: false,
      approximate_date: false,
    };
  }

  componentDidMount = () => {
    this.getLocations();
    this.getMatchFormats();
  };

  handleDateChange = (value) => {
    this.setState({ match_date: value });
  };

  handleChangeMinutesPerTime = (e) => {
    e.preventDefault();
    let numbers = /^\s*\d*\s*$/;
    if (e.target.value.match(numbers)) {
      this.setState({ minutes_per_time: e.target.value });
    }
  };

  getMatchFormats = () => {
    axios
      .get(`matches/manual_match_formats`)
      .then((res) => {
        let matchFormats = [
          ...res.data.formats,
          { value: "0", label: this.props.languagePack.other_format },
        ];
        this.setState({
          match_formats: matchFormats,
          selected_match_format: matchFormats[0],
        });
      })
      .catch((err) => console.log(err));
  };

  getCompetitions = () => {
    axios
      .get(`matches/get_competitions?id_club=${this.props.currentUser.id_club}`)
      .then((res) => {
        let array = [];
        res.data.map((elem) => {
          let item = {
            id: elem.key,
            name: elem.text,
            groups: [],
            sub_comps: elem.sub_comps,
            multiple_age_groups: elem.multiple_age_groups,
          };
          array.push(item);
        });

        this.setState({ competitions: array });
      })
      .catch((err) => console.log(err));
  };

  getDetailsCompetition = (id) => {
    axios
      .get(`matches/competition_type?id_comp=${id}`)
      .then((res) => {
        let groups = [];
        res.data.groups.map((item) => {
          return groups.push({
            value: item.id_competition_group,
            label: item.group_name,
          });
        });
        this.setState((data) => ({
          competition: {
            ...data.competition,
            groups: groups,
          },
          minutes_per_time: res.data.minutes_per_time,
        }));
      })
      .catch((err) => console.log(err));
  };

  getTeams = (id) => {
    axios
      .get(
        `matches/get_competition_teams2?id_competition=${id}&id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => {
        let competitionTeams = [];
        res.data.forEach((team) =>
          competitionTeams.push({
            value: team.id_manual_competition_team,
            label: team.team_name,
          })
        );
        this.setState({ teams: competitionTeams });
      })
      .catch((err) => console.log(err));
  };

  getClubTeams = () => {
    axios
      .get(
        `club/get_teams?id_club=${this.props.currentUser.id_club}&token=${this.props.token}`
      )
      .then((res) => {
        let teamsOptions = [];
        res.data.forEach((team) => {
          teamsOptions.push({
            value: team.id_team,
            label: team.team_name,
          });
        });
        teamsOptions.unshift({
          value: "0",
          label: "Echipa externa",
        });

        this.setState({ teams: teamsOptions });
      })
      .catch((err) => console.log(err));
  };

  getLocations = () => {
    axios
      .get(`site/get_locations?id_club=${this.props.currentUser.id_club}`)
      .then((res) => {
        // let venuesArray = [{ value: "0", label: "Adauga locatie noua" }];
        let venuesArray = [
          { value: "0", label: this.props.languagePack.add_new_match_venue },
        ];
        res.data.forEach((venue) => {
          venuesArray.push({
            value: venue.id_venue,
            label: venue.venue_name,
          });
        });
        this.setState({ locations: venuesArray });
      })
      .catch((err) => console.log(err));
  };

  handleChangeMatchFormat = (selectedFormat) => {
    if (selectedFormat.value == "0") {
      this.setState({
        selected_match_format: selectedFormat,
        other_match_format: true,
      });
      return;
    }
    this.setState({
      selected_match_format: selectedFormat,
      other_match_format: false,
    });
  };

  areFieldsValid = () => {
    const checkTeamHomeFriendly =
      this.state.team_home == null &&
      this.state.extern_team == null &&
      this.state.extern_team_type == "home";

    const checkTeamAwayFriendly =
      this.state.team_away == null &&
      this.state.extern_team == "" &&
      this.state.extern_team_type == "away";

    const checkMatchFormat =
      this.state.other_match_format &&
      (this.state.new_match_format == null ||
        this.state.new_match_format == "");

    const checkLocation =
      this.state.add_new_location &&
      (this.state.new_location == null || this.state.new_location == "");

    const checkMatchDate = this.state.match_date == null;

    const checkCompetition =
      this.state.competition.id == "" ||
      (this.state.competition.multiple_age_groups > 0 &&
        this.state.sub_comp.id == "");

    if (
      this.state.match_type == "friendly" &&
      (checkTeamHomeFriendly ||
        checkTeamAwayFriendly ||
        checkMatchFormat ||
        checkLocation ||
        checkMatchDate)
    )
      return false;

    if (
      this.state.match_type != "friendly" &&
      (checkCompetition ||
        this.state.team_home == null ||
        this.state.team_away == null ||
        this.state.team_away == null ||
        this.state.group == null ||
        checkMatchFormat ||
        checkLocation ||
        checkMatchDate)
    )
      return false;

    return true;
  };

  getOpponentLogo = () => {
    const payload = {
      query: "logo " + removeAgeGroupFromTeamName(this.state.extern_team),
    };
    this.setState({ loading_logos: true });
    axios
      .post("matches/get_team_logo", payload)
      .then((res) =>
        this.setState({
          loading_logos: false,
          extern_team_logos: res?.data?.slice(0, 3),
        })
      );
  };

  handleSearchExternTeamLogo = () => {
    if (_.size(this.state.extern_team) >= 3) {
      this.getOpponentLogo();
      this.setState({ show_extern_team_logos: true });
    }
  };

  handleChangeSelectedLogo = (i) => {
    this.setState({ extern_team_selected_logo: i });
  };

  getImageFromFile = (files, file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      this.setState({ extern_team_uploaded_logo: reader.result });
    }.bind(this);
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  handleChangUploadedLogo = (e) => {
    if (_.size(e.target.files) > 0) {
      this.getImageFromFile(e.target.files, e.target.files[0]);
    } else {
      this.setState({ extern_team_uploaded_logo: "" });
    }
  };

  handleRemoveUploadedLogo = () => {
    this.setState({ extern_team_uploaded_logo: "" });
  };

  addNewMatch = () => {
    let payload;
    if (this.state.match_type == "friendly") {
      payload = {
        id_competition: -1,
        id_team_home:
          this.state.extern_team_type == "home"
            ? this.state.extern_team
            : this.state.team_home.value,
        id_team_away:
          this.state.extern_team_type == "away"
            ? this.state.extern_team
            : this.state.team_away.value,
        match_date: moment(this.state.match_date).format("YYYY-MM-DD HH:mm:ss"),
        approximate_date: { true: 1, false: 0 }[this.state.approximate_date],
        id_club: this.props.currentUser.id_club,
        match_type: this.state.match_type,
        external_team_logo: this.state.logos_checkbox
          ? null
          : this.state.extern_team_uploaded_logo !== ""
            ? "uploaded logo"
            : this.state.extern_team_logos[this.state.extern_team_selected_logo]
              ?.thumbnail_url,
        uploaded_logo_base64:
          this.state.extern_team_uploaded_logo !== "" &&
            !this.state.logos_checkbox
            ? this.state.extern_team_uploaded_logo
            : null,
      };
    } else {
      payload = {
        id_competition:
          this.state.competition.multiple_age_groups == "0"
            ? this.state.competition.id
            : this.state.sub_comp.id,
        round: this.state.match_round,
        id_team_home: this.state.team_home.value,
        id_team_away: this.state.team_away.value,
        match_date: moment(this.state.match_date).format("YYYY-MM-DD HH:mm:ss"),
        approximate_date: { true: 1, false: 0 }[this.state.approximate_date],
        id_competition_group: this.state.group,
        id_club: this.props.currentUser.id_club,
        match_type: this.state.match_type,
      };
    }

    payload.match_format = this.state.selected_match_format.value;
    payload.another_format_name = this.state.other_match_format
      ? this.state.new_match_format
      : null;
    payload.minutes_per_time = this.state.minutes_per_time;

    if (this.state.add_new_location) {
      payload.venue_name = this.state.new_location;
      payload.add_venue_to_mng = { false: "0", true: "1" }[
        this.state.add_venue_to_mng
      ];
    } else {
      payload.id_venue = this.state.location.value;
    }

    payload.is_tfb = isTFB();

    axios.post("matches/add_match_2", payload).then((res) => {
      if (res.data.success == 1) {
        store.addNotification({
          title: this.props.languagePack["fixtures_schedule"],
          message: this.props.languagePack["match_successfully_added"],
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });

        let clubQuickSetup = res.data.club_quick_setup;
        this.props.updateQuickSetup({
          ...clubQuickSetup,
          isSetupOpen: false,
        });

        this.props.getMatches();
        this.props.closePopup();
      }
    });
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="add-match-container">
        <div className="add-match-header">
          <div className="add-match-title">{languagePack["add_match"]}</div>
          <Icon
            name="close"
            onClick={() => this.props.closePopup()}
            className="close-icon-popup"
          />
        </div>
        <div className="add-match-row">
          <div className="add-team-dropdown">
            <select
              value={this.state.match_type}
              onChange={(e) => {
                this.setState(
                  {
                    match_type: e.target.value,
                    approximate_date: false,
                    team_away: null,
                    team_home: null,
                  },
                  async () => {
                    if (this.state.match_type == "friendly") {
                      this.getClubTeams();
                    } else {
                      this.getCompetitions();
                    }
                  }
                );
              }}
            >
              <option value="" disabled defaultValue={""}>
                {languagePack.select_match_type}
              </option>
              <option value={"competition"}>
                {languagePack.match_from_competition}
              </option>
              <option value={"friendly"}>{languagePack.frendly_match}</option>
            </select>
          </div>
        </div>
        {this.state.match_type.length > 0 &&
          (this.state.match_type == "friendly" ? (
            <>
              <div className="add-match-row">
                <TFBInput
                  label={languagePack.minutes_per_time}
                  placeholder={languagePack.minutes_per_time}
                  type="number"
                  value={this.state.minutes_per_time}
                  onChange={(e) => this.handleChangeMinutesPerTime(e)}
                  style={{ width: "100%" }}
                />
              </div>

              <div className="add-match-row">
                <TFBSelect
                  label={languagePack.match_format}
                  placeholder={languagePack.match_format}
                  options={this.state.match_formats}
                  value={this.state.selected_match_format}
                  onChange={(selected) =>
                    this.handleChangeMatchFormat(selected)
                  }
                />
              </div>

              {this.state.other_match_format && (
                <div className="add-match-row">
                  <input
                    placeholder={languagePack.add_match_format}
                    className="mintes-per-time-input"
                    value={this.state.new_match_format}
                    onChange={(e) => {
                      this.setState({ new_match_format: e.target.value });
                    }}
                  />
                </div>
              )}

              {(this.state.extern_team_type == "" ||
                this.state.extern_team_type != "home") && (
                  <div className="add-match-row">
                    <div className="add-team-dropdown">
                      <TFBSelect
                        placeholder={languagePack["home_team"]}
                        value={this.state.team_home}
                        options={this.state.teams}
                        onChange={(selected) => {
                          if (selected.value == "0") {
                            this.setState({ extern_team_type: "home" });
                          } else {
                            this.setState({ team_home: selected });
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
              {this.state.extern_team_type == "home" && (
                <div className="add-match-row div-extern-logos">
                  <div className="team-name-logo-div-1">
                    <TFBInput
                      fullWidth
                      placeholder={languagePack["home_team"]}
                      label={languagePack["home_team"]}
                      value={this.state.extern_team}
                      onChange={(e) =>
                        this.setState({ extern_team: e.target.value })
                      }
                    />
                    <TFBButton
                      size="sm"
                      style={{ height: "53px", width: "200px" }}
                      onClick={this.handleSearchExternTeamLogo}
                      renderIcon={() => <TFBIcon name="search" />}
                      color="green"
                    >
                      {languagePack.search_logo}
                    </TFBButton>
                  </div>
                  {this.state.show_extern_team_logos && (
                    <>
                      {!this.state.logos_checkbox && (
                        <ExternTeamLogos
                          uploadedLogo={this.state.extern_team_uploaded_logo}
                          onRemoveUploadedLogo={this.handleRemoveUploadedLogo}
                          onChangeFile={this.handleChangUploadedLogo}
                          loading={this.state.loading_logos}
                          setSelectedLogo={this.handleChangeSelectedLogo}
                          selectedLogo={this.state.extern_team_selected_logo}
                          thumbnails={_.map(
                            this.state.extern_team_logos,
                            (logo) => logo.thumbnail_url
                          )}
                        />
                      )}
                      <div className="add-checkbox-input extern-team-checkbox">
                        <TFBCheckbox
                          label={
                            languagePack.continue_without_external_team_logo
                          }
                          checked={this.state.logos_checkbox}
                          onChange={(e) =>
                            this.setState({
                              logos_checkbox: e.target.checked,
                            })
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
              {(this.state.extern_team_type == "" ||
                this.state.extern_team_type != "away") && (
                  <div className="add-match-row">
                    <div className="add-team-dropdown">
                      <TFBSelect
                        placeholder={languagePack["away_team"]}
                        value={this.state.team_away}
                        options={this.state.teams}
                        onChange={(selected) => {
                          if (selected.value == "0") {
                            this.setState({ extern_team_type: "away" });
                          } else {
                            this.setState({ team_away: selected });
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
              {this.state.extern_team_type == "away" && (
                <div className="add-match-row div-extern-logos">
                  <div className="team-name-logo-div-1">
                    <TFBInput
                      fullWidth
                      label={languagePack["away_team"]}
                      placeholder={languagePack["away_team"]}
                      value={this.state.extern_team}
                      onChange={(e) =>
                        this.setState({ extern_team: e.target.value })
                      }
                    />
                    <TFBButton
                      size="sm"
                      style={{ height: "53px", width: "200px" }}
                      onClick={this.handleSearchExternTeamLogo}
                      renderIcon={() => <TFBIcon name="search" />}
                      color="green"
                    >
                      {languagePack.search_logo}
                    </TFBButton>
                  </div>
                  {this.state.show_extern_team_logos && (
                    <>
                      {!this.state.logos_checkbox && (
                        <ExternTeamLogos
                          uploadedLogo={this.state.extern_team_uploaded_logo}
                          onRemoveUploadedLogo={this.handleRemoveUploadedLogo}
                          onChangeFile={this.handleChangUploadedLogo}
                          loading={this.state.loading_logos}
                          setSelectedLogo={this.handleChangeSelectedLogo}
                          selectedLogo={this.state.extern_team_selected_logo}
                          thumbnails={_.map(
                            this.state.extern_team_logos,
                            (logo) => logo.thumbnail_url
                          )}
                        />
                      )}
                      <div className="add-checkbox-input extern-team-checkbox">
                        <TFBCheckbox
                          label={
                            languagePack.continue_without_external_team_logo
                          }
                          checked={this.state.logos_checkbox}
                          onChange={(e) =>
                            this.setState({
                              logos_checkbox: e.target.checked,
                            })
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
              <div className="add-match-row">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="date-input">
                    <DateTimePicker
                      value={this.state.match_date}
                      onChange={this.handleDateChange}
                      ampm={false}
                      format="dd MMMM HH:mm"
                      inputVariant="outlined"
                      name="match_date"
                      className="add-match-date-picker"
                      emptyLabel={languagePack["match_date"]}
                    />
                    <Icon
                      name="calendar alternate outline"
                      className="calendar-icon"
                      size="small"
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </div>
              <div className="add-match-row">
                <div className="add-checkbox-input">
                  <TFBCheckbox
                    label={languagePack.date_and_time_are_approximate}
                    checked={this.state.approximate_date}
                    onChange={(e) => {
                      this.setState({
                        approximate_date: e.target.checked,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="add-match-row">
                <div className="add-team-dropdown">
                  <TFBSelect
                    placeholder={languagePack["select_match_venue"]}
                    value={this.state.location}
                    options={this.state.locations}
                    onChange={(selected) => {
                      if (selected.value == "0") {
                        this.setState({ add_new_location: true });
                      } else {
                        this.setState({ add_new_location: false });
                      }
                      this.setState({ location: selected });
                    }}
                  />
                </div>
              </div>
              {this.state.add_new_location && (
                <>
                  <div className="add-match-row">
                    <TFBInput
                      placeholder={languagePack["new_match_venue"]}
                      label={languagePack["new_match_venue"]}
                      className="round-input"
                      value={this.state.new_location}
                      onChange={(e) =>
                        this.setState({ new_location: e.target.value })
                      }
                    />
                  </div>
                  <div className="add-match-row">
                    <div className="add-checkbox-input">
                      <TFBCheckbox
                        label={languagePack.add_location_to_field_management}
                        checked={this.state.add_venue_to_mng}
                        onChange={(e) => {
                          this.setState({
                            add_venue_to_mng: e.target.checked,
                          });
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="add-match-row">
                <div className="add-team-dropdown">
                  <select
                    value={this.state.competition.name}
                    onChange={(e) => {
                      this.setState(
                        {
                          competition: {
                            id: e.target.selectedOptions[0].getAttribute(
                              "id_elem"
                            ),
                            name: e.target.value,
                            groups: e.target.selectedOptions[0].getAttribute(
                              "groups"
                            ),
                            multiple_age_groups: e.target.selectedOptions[0].getAttribute(
                              "multiple_age_groups"
                            ),
                            sub_comps: this.state.competitions.filter(
                              (elem) =>
                                elem.id ==
                                e.target.selectedOptions[0].getAttribute(
                                  "id_elem"
                                )
                            )[0].sub_comps,
                          },
                          sub_comp: {
                            id: "",
                            name: "",
                            groups: [],
                          },
                        },
                        async () => {
                          this.getTeams(this.state.competition.id);
                          this.getDetailsCompetition(this.state.competition.id);
                        }
                      );
                    }}
                  >
                    <option value="" disabled selected>
                      {languagePack["select_competition"]}
                    </option>
                    {this.state.competitions.map((item, index) => {
                      return (
                        <option
                          value={item.name}
                          key={index}
                          id_elem={item.id}
                          groups={item.groups}
                          sub_comps={item.sub_comps}
                          multiple_age_groups={item.multiple_age_groups}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {this.state.competition.sub_comps.length > 0 && (
                <div className="add-match-row">
                  <div className="add-team-dropdown">
                    <select
                      value={this.state.sub_comp.name}
                      onChange={(e) => {
                        this.setState(
                          {
                            sub_comp: {
                              id: e.target.selectedOptions[0].getAttribute(
                                "id_elem"
                              ),
                              name: e.target.value,
                              groups: e.target.selectedOptions[0].getAttribute(
                                "groups"
                              ),
                            },
                            add_match_details: true,
                          },
                          async () => {
                            this.getTeams(this.state.sub_comp.id);
                            this.getDetailsCompetition(this.state.sub_comp.id);
                          }
                        );
                      }}
                    >
                      <option value="" disabled selected>
                        {languagePack["select_age_group"]}
                      </option>
                      {this.state.competition.sub_comps.map((item, index) => {
                        return (
                          <option
                            value={item.competition_name}
                            key={index}
                            id_elem={item.id_competition}
                            groups={item.groups}
                          >
                            {item.competition_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {((this.state.competition.id !== "" &&
                this.state.competition.multiple_age_groups == "0") ||
                this.state.sub_comp.id !== "") && (
                  <>
                    <div className="add-match-row">
                      <TFBInput
                        placeholder={languagePack["round_number_name"]}
                        label={languagePack["round_number_name"]}
                        className="round-input"
                        value={this.state.match_round}
                        onChange={(e) =>
                          this.setState({ match_round: e.target.value })
                        }
                      />
                    </div>

                    <div className="add-match-row">
                      <TFBInput
                        label={languagePack.minutes_per_time}
                        placeholder={languagePack.minutes_per_time}
                        type="number"
                        value={this.state.minutes_per_time}
                        onChange={(e) => this.handleChangeMinutesPerTime(e)}
                        style={{ width: "100%" }}
                      />
                    </div>

                    <div className="add-match-row">
                      <TFBSelect
                        label={languagePack.match_format}
                        placeholder={languagePack.match_format}
                        options={this.state.match_formats}
                        value={this.state.selected_match_format}
                        onChange={(selected) =>
                          this.handleChangeMatchFormat(selected)
                        }
                      />
                    </div>

                    {this.state.other_match_format && (
                      <div className="add-match-row">
                        <TFBInput
                          placeholder={languagePack.add_match_format}
                          label={languagePack.add_match_format}
                          className="match-format-input"
                          value={this.state.new_match_format}
                          onChange={(e) => {
                            this.setState({ new_match_format: e.target.value });
                          }}
                        />
                      </div>
                    )}

                    <div className="add-match-row">
                      <div className="add-team-dropdown">
                        <TFBSelect
                          placeholder={languagePack["home_team"]}
                          value={this.state.team_home}
                          options={this.state.teams}
                          onChange={(selected) => {
                            this.setState({ team_home: selected });
                          }}
                        />
                      </div>
                    </div>
                    <div className="add-match-row">
                      <div className="add-team-dropdown">
                        <TFBSelect
                          placeholder={languagePack["away_team"]}
                          value={this.state.team_away}
                          options={this.state.teams}
                          onChange={(selected) => {
                            this.setState({ team_away: selected });
                          }}
                        />
                      </div>
                    </div>
                    {this.state.competition.groups.length > 0 ? (
                      <div className="add-match-row">
                        <div className="group-dropdown add-team-dropdown">
                          <select
                            value={this.state.group}
                            onChange={(e) => {
                              this.setState({ group: e.target.value });
                            }}
                          >
                            <option value="" disabled selected>
                              {languagePack["select_group"]}
                            </option>
                            {this.state.competition.groups.map((item) => {
                              return (
                                <option value={item.value} key={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    ) : null}
                    <div className="add-match-row">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="date-input">
                          <DateTimePicker
                            value={this.state.match_date}
                            onChange={this.handleDateChange}
                            ampm={false}
                            format="dd MMMM HH:mm"
                            inputVariant="outlined"
                            name="match_date"
                            className="add-match-date-picker"
                            emptyLabel={languagePack["match_date"]}
                          />
                          <Icon
                            name="calendar alternate outline"
                            className="calendar-icon"
                            size="small"
                          />
                        </div>
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="add-match-row">
                      <div className="add-checkbox-input">
                        <TFBCheckbox
                          label={languagePack.date_and_time_are_approximate}
                          checked={this.state.approximate_date}
                          onChange={(e) => {
                            this.setState({
                              approximate_date: e.target.checked,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="add-match-row">
                      <div className="add-team-dropdown">
                        <TFBSelect
                          placeholder={languagePack["select_match_venue"]}
                          value={this.state.location}
                          options={this.state.locations}
                          onChange={(selected) => {
                            if (selected.value == "0") {
                              this.setState({ add_new_location: true });
                            } else {
                              this.setState({ add_new_location: false });
                            }
                            this.setState({ location: selected });
                          }}
                        />
                      </div>
                    </div>
                    {this.state.add_new_location && (
                      <>
                        <div className="add-match-row">
                          <TFBInput
                            placeholder={languagePack["new_match_venue"]}
                            label={languagePack["new_match_venue"]}
                            className="round-input"
                            value={this.state.new_location}
                            onChange={(e) =>
                              this.setState({ new_location: e.target.value })
                            }
                          />
                        </div>
                        <div className="add-match-row">
                          <div className="add-checkbox-input">
                            <TFBCheckbox
                              label={
                                languagePack.add_location_to_field_management
                              }
                              checked={this.state.add_venue_to_mng}
                              onChange={(e) => {
                                this.setState({
                                  add_venue_to_mng: e.target.checked,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
            </>
          ))}
        <div className="add-match-buttons" style={{ gap: "15px" }}>
          <TFBButton
            color="darkGray"
            endIcon={true}
            renderIcon={() => <TFBIcon name="cancel" />}
            style={{ width: "50%" }}
            onClick={() => this.props.closePopup()}
          >
            {languagePack["admin_cancel"]}
          </TFBButton>

          <TFBButton
            color="green"
            endIcon={true}
            renderIcon={() => <TFBIcon name="add" />}
            style={{ width: "50%" }}
            disabled={!this.areFieldsValid()}
            onClick={() => {
              this.addNewMatch();
            }}
          >
            {languagePack["admin_add"]}
          </TFBButton>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateQuickSetup,
    },
    dispatch
  );
}

export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(AddMatchFormDirect);
