import React, { useReducer } from "react";
import { connect } from "react-redux";
import moment from "moment";
import _, { clone, filter, includes, map, size, trim } from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";
import useRenderButton from "../../../components/customHooks/useRenderButton";
import { TFBTableCell } from "../../../components/reusable/useStyles";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBDatePicker from "../../../components/design-system/DatePicker/TFBDatePicker";
import TFBTextArea from "../../../components/design-system/TextArea/TFBTextArea";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";

import DeletePayment from "./DeletePayment";

const EditPayment = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, currentUser, data, year } = props;

    const { lang } = currentUser;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            player_first_month_year_for_payment: data?.first_month_year_for_payment ?? null,
            monthly_tax: data?.monthly_tax ?? "",
            tax_reason: data?.tax_reason ?? "",
            serial_number: data?.serial_number ?? "",

            open_delete_payment: false,
            deleting_payment: {},

            payments: data?.payments?.list ?? [],
            exceptions: map(data?.conditions?.list ?? [], value => value),
        }
    );

    const { postData: updateRequest, loading: loadingUpdateRequest } = useAxiosPost("payment/update_player_payment_details");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            refreshList();
            closePopup();
            showNotification(
                "success",
                languagePack.finances,
                languagePack.update_record_event_success,
            );
        } else {
            showNotification(
                "danger",
                languagePack.finances,
                languagePack.update_record_event_fail,
            );
        }
    };

    const openDeletePayment = (payment) => {
        updateState({ open_delete_payment: true, deleting_payment: payment });
    }

    const closeDeletePayment = () => {
        updateState({ open_delete_payment: false, deleting_payment: {} });
    }

    const updatePaymentDetails = () => {
        let payload = {
            id_player: data.id_player,
            player_data: {
                first_month_year_for_payment: state.player_first_month_year_for_payment,
                monthly_tax: state.monthly_tax,
                tax_reason: state.tax_reason,
                serial_number: state.serial_number,
            },
            year,
            conditions: map(filter(state.exceptions, e => e?.new ? (e.month && trim(e.amount) !== "") : true), e => ({ amount: e.amount, month: e.month?.value ? e.month.value : e.month })),
        };

        updateRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => updatePaymentDetails(),
        "green",
        "add",
        languagePack.admin_save,
        {},
        true,
        false,
        loadingUpdateRequest,
    );

    const refreshPaymentList = (id_payment) => {
        const index = _.findIndex(state.payments, e => e.id_payment == id_payment);
        if (index > -1) {
            updateState({ payments: _.filter(state.payments, (e, i) => i != index) })
            refreshList();
        }
    }

    const addExceptionLine = () => {
        updateState({ exceptions: [...[{ new: true, month: null, amount: "" }], ...state.exceptions] });
    }

    const removeExceptionLine = (index) => {
        let list = clone(state.exceptions);
        list.splice(index, 1);
        updateState({ exceptions: list });
    }

    const handleException = (selected, index, field) => {
        let list = clone(state.exceptions);
        list[index] = { ...list[index], [field]: selected }
        updateState({ exceptions: list });
    }

    const canAddNewException = () => size(filter(state.exceptions, e => e?.new && (!e.month || trim(e.amount) === ""))) > 0;

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["edit_payments_title"] + " " + data.player_name}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <TFBInput
                label={"Matricol"}
                value={state.serial_number}
                onChange={(e) => updateState({ serial_number: e.target.value })}
                style={{ marginBottom: 20, width: "100%" }}
            />
            <TFBDatePicker
                value={state.player_first_month_year_for_payment}
                onChange={(value) => updateState({ player_first_month_year_for_payment: value })}
                openTo="month"
                views={["year", "month"]}
                label={"Luna si anul primei cotizatii"}
                format="MMMM yyyy"
                style={{ marginBottom: 20, width: "100%" }}
            />
            <TFBInput
                label={languagePack.monthly_tax}
                value={state.monthly_tax}
                onChange={(e) => updateState({ monthly_tax: e.target.value })}
                style={{ marginBottom: 20, width: "100%" }}
            />
            <TFBTextArea
                label={"Motivare taxă"}
                value={state.tax_reason}
                onChange={(e) => updateState({ tax_reason: e.target.value })}
                style={{ marginBottom: 20, width: "100%" }}
            />
            <div style={{ marginBottom: 30 }}>
                <div className="df df-gap-10 df-sb df-center ">
                    <div className="ds-headline-1">{languagePack.special_monthly_tax_for_year + year}</div>
                    <TFBIconButton
                        name="add"
                        color="green"
                        onClick={addExceptionLine}
                        disabled={canAddNewException()}
                    />
                </div>
                {size(state.exceptions) > 0 ?
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TFBTableCell align="center">{languagePack.month}</TFBTableCell>
                                    <TFBTableCell align="center">{languagePack.sum}</TFBTableCell>
                                    <TFBTableCell align="center">{languagePack.product_actions}</TFBTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(state.exceptions, (exception, index) => (
                                    <TableRow
                                        key={"player-payment-exception" + index}
                                        sx={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white", }}
                                    >
                                        <TFBTableCell align="center">
                                            {exception?.new ?
                                                <TFBSelect
                                                    value={exception.month}
                                                    onChange={selected => handleException(selected, index, "month")}
                                                    options={_.map(filter(_.range(12), m => !includes(map(filter(state.exceptions, i => i.month), e => Number(e.month?.value || e.month)), m + 1)), (v) => ({
                                                        value: v + 1,
                                                        label: _.capitalize(
                                                            moment()
                                                                .set("month", v)
                                                                .locale(lang ?? "ro")
                                                                .format("MMMM")
                                                        ),
                                                    }))}
                                                    style={{
                                                        container: { width: "130px" },
                                                        control: { backgroundColor: "white" },
                                                        valueContainer: { paddingLeft: 10 }
                                                    }}
                                                /> :
                                                _.capitalize(moment().set("month", exception.month - 1).locale(lang ?? "ro").format('MMMM'))
                                            }
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                            {exception?.new ?
                                                <TFBInput
                                                    value={exception.amount}
                                                    onChange={(e) => handleException(e.target.value, index, "amount")}
                                                    style={{ width: "100%", backgroundColor: "white" }}
                                                    type="number"
                                                /> :
                                                exception.amount + " RON"
                                            }
                                        </TFBTableCell>
                                        <TFBTableCell>
                                            <div className="actions-btns-container">
                                                <TFBIconButton
                                                    name="delete"
                                                    color="darkGray"
                                                    onClick={() => removeExceptionLine(index)}
                                                />
                                            </div>
                                        </TFBTableCell>
                                    </TableRow>
                                )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer> :
                    <TFBPlaceholder text={languagePack["there_are_no_records"]} />
                }
            </div>
            <div className="ds-headline-1">{"Lista plati lunare"}</div>
            {_.size(state.payments) > 0 ?
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TFBTableCell align="center">{languagePack.month}</TFBTableCell>
                                <TFBTableCell align="center">{languagePack.sum}</TFBTableCell>
                                <TFBTableCell align="center">{languagePack.payment_type}</TFBTableCell>
                                <TFBTableCell align="center">{languagePack.product_actions}</TFBTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(state.payments, (payment, index) => (
                                <TableRow
                                    key={"player-payment-" + index}
                                    sx={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white", }}
                                >
                                    <TFBTableCell>
                                        {_.capitalize(moment().set("month", payment.payment_month - 1).locale(lang ?? "ro").format('MMMM'))}
                                    </TFBTableCell>
                                    <TFBTableCell>
                                        {payment.payment_amount + " RON"}
                                    </TFBTableCell>
                                    <TFBTableCell>
                                        {languagePack.payment_types?.[payment.payment_type_label]}
                                    </TFBTableCell>
                                    <TFBTableCell>
                                        <div className="actions-btns-container">
                                            {!["parents_app", "online_website",].includes(payment.payment_type_label) &&
                                                <TFBIconButton
                                                    name="delete"
                                                    color="darkGray"
                                                    onClick={() => openDeletePayment(payment)}
                                                />
                                            }
                                        </div>
                                    </TFBTableCell>
                                </TableRow>
                            )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer> :
                <TFBPlaceholder text={languagePack["edit_payments_empty"]} />
            }
            {state.open_delete_payment &&
                <DeletePayment
                    openPopup={state.open_delete_payment}
                    closePopup={closeDeletePayment}
                    refreshList={refreshPaymentList}
                    payment={state.deleting_payment}
                />
            }
        </TFBDialog>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(EditPayment);
