import { styled } from "@mui/material/styles";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { TablePagination, tablePaginationClasses } from "@mui/material";

export const TFBTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#B2B2B2",
    backgroundColor: theme.palette.common.white,
    fontFamily: "Poppins",
    fontWeight: 700,
    borderBottom: 0,
    fontSize: 12,
    minHeight: 40,
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Poppins",
    color: "#666666",
    fontWeight: 700,
    borderBottom: 0,
    minHeight: 55,
    fontSize: 12,
  },
}));

export const TFBTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.common.white,
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "#fafafa",
  },
}));

export const sxStickyColumn = {
  position: "sticky",
  left: 0,
  zIndex: 5,
  backgroundColor: "inherit",
};

export const sxStickyRow = {
  position: "sticky",
  top: 0,
  zIndex: 6,
};

export const TFBTablePagination = styled(TablePagination)(({ theme }) => ({
  [`& .${tablePaginationClasses.displayedRows}`]: {
    marginBottom: 0,
    fontFamily: "Poppins",
    color: "#666666",
    fontWeight: 700,
    fontSize: 12,
  }
}));
