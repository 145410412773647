import TFBCard from "../../../components/design-system/Card/TFBCard";
import { useHistory } from "react-router-dom";
import React, { useEffect, useReducer } from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBDropdown from "../../../components/design-system/Dropdown/TFBDropdown";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";

import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import _ from "lodash";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../components/reusable/useStyles";
import TFBTeamLabel from "../../../components/design-system/TFBTeamLabel/TFBTeamLabel";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import { baseUrl } from "../../../utils/utilFunctions";

function PerTeamReceiptsStats({ languagePack, token, currentUser }) {
  const history = useHistory();

  const initialOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "80%",
        borderRadius: "3px",
      },
    },
    grid: {
      row: {
        colors: ["#f5f5f5", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    legend: {
      position: "top",
    },
    xaxis: {
      categories: [],
      labels: {
        maxHeight: 220,
        style: {
          colors: " #B2B2B2",
          fontFamily: "Poppins",
          fontSize: "13px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "9px",
        },
        rotate: -70,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: " #B2B2B2",
          fontFamily: "Poppins",
          fontSize: "13px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "9px",
        },
        formatter: function (value) {
          return value.toLocaleString() + " RON";
        },
      },
    },
    colors: ["#00d948", "#666666"],
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toLocaleString();
        },
      },
    },
  };
  const initialState = {
    tableOptions: initialOptions,
    tableSeries: [
      {
        data: [],
        name: languagePack.incoming_payments,
      },
      {
        data: [],
        name: languagePack.expected_incoming_payments,
      },
    ],
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,

    teamsList: [],
    paymentsData: [],
  };

  function reducer(state, action) {
    switch (action.type) {
      case "changeYear":
        return { ...state, year: action.payload };
      case "changeMonth":
        return { ...state, month: action.payload };
      case "updatePaymentsData":
        return { ...state, paymentsData: action.payload };
      case "updateTeamList":
        return {
          ...state,
          tableOptions: {
            ...state.tableOptions,
            xaxis: { ...state.tableOptions.xaxis, categories: action.payload },
          },
        };
      case "updatePaymentsPerTeam":
        return {
          ...state,
          tableSeries: [
            ...state.tableSeries,
            { name: languagePack.incoming_payments, data: action.payload },
          ],
        };
      case "updateExpectedPaymentsPerTeam":
        return {
          ...state,
          tableSeries: [
            ...state.tableSeries,
            {
              name: languagePack.expected_incoming_payments,
              data: action.payload,
            },
          ],
        };
      case "trim":
        return {
          ...state,
          tableSeries: _.filter(
            _.map(state.tableSeries, (el, i, arr) =>
              i > arr.length - 3 ? el : ""
            ),
            (el) => el !== ""
          ),
        };

      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  //////                   G E T   P A Y M E N T S  P E R   T E A M               ////////

  const {
    data: perTeamPayments,
    refetch: refetchPerTeamPayments,
  } = useAxiosGet(
    `finances/get_data_for_chart_payments_team?id_club=${currentUser.id_club}&month=${state.month}&year=${state.year}&token=${token}`
  );

  useEffect(() => {
    const paymentsArray = _.map(
      perTeamPayments?.teams?.filter((t) => t["total_paid"] != null),
      (t) => Number(t.total_paid)
    );

    const teamsList = _.map(
      perTeamPayments?.teams?.filter((t) => t["total_paid"] != null),
      (t) => t.team_name
    );

    const teamsExpectedList = _.map(
      perTeamPayments?.teams?.filter((t) => t["total_paid"] != null),
      (t) => (Number(t.total_paid) * 100) / Number(t.payment_percent).toFixed(2)
    );

    dispatch({
      type: "updateTeamList",
      payload: teamsList,
    });
    dispatch({ type: "updatePaymentsData", payload: perTeamPayments?.teams });
    dispatch({ type: "updatePaymentsPerTeam", payload: paymentsArray });
    dispatch({
      type: "updateExpectedPaymentsPerTeam",
      payload: teamsExpectedList,
    });
    dispatch({
      type: "trim",
    });
  }, [perTeamPayments, languagePack]);

  return (
    <TFBCard>
      <TFBCardHeader title={languagePack.receipts_stats_per_team}>
        <TFBDropdown
          selection={true}
          color="gray"
          style={{ width: "250px" }}
          label={languagePack.year}
          name="year"
          options={[
            { key: 0, value: 2024, text: "2024" },
            { key: 1, value: 2023, text: "2023" },
            { key: 2, value: 2022, text: "2022" },
            { key: 3, value: 2021, text: "2021" },
          ]}
          value={state.year}
          onChange={(e, v) =>
            dispatch({ type: "changeYear", payload: v.value })
          }
        />
        <TFBDropdown
          color="gray"
          style={{ width: "250px" }}
          label={languagePack.month}
          name="month"
          selection={true}
          value={state.month - 1}
          options={[
            { key: 0, value: 0, text: languagePack.month_1 },
            { key: 1, value: 1, text: languagePack.month_2 },
            { key: 2, value: 2, text: languagePack.month_3 },
            { key: 3, value: 3, text: languagePack.month_4 },
            { key: 4, value: 4, text: languagePack.month_5 },
            { key: 5, value: 5, text: languagePack.month_6 },
            { key: 6, value: 6, text: languagePack.month_7 },
            { key: 7, value: 7, text: languagePack.month_8 },
            { key: 8, value: 8, text: languagePack.month_9 },
            { key: 9, value: 9, text: languagePack.month_10 },
            { key: 10, value: 10, text: languagePack.month_11 },
            { key: 11, value: 11, text: languagePack.month_12 },
          ]}
          onChange={(e, v) =>
            dispatch({ type: "changeMonth", payload: v.value + 1 })
          }
        ></TFBDropdown>
      </TFBCardHeader>
      <TFBCardBody>
        <div>
          <Chart
            options={state.tableOptions}
            series={state.tableSeries}
            type="bar"
            height={530}
            width={"100%"}
          />
        </div>
        <div style={{ marginTop: "50px" }}>
          <TableContainer>
            <Table faria-label="simple table">
              <TableHead>
                <TableRow>
                  <TFBTableCell sx={sxStickyColumn} align="left">
                    {languagePack.team_name}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.number_of_players_that_paid}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.paid_sum + " (RON)"}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.total_to_be_paid + " (RON)"}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.percentage_of_total_paid}
                  </TFBTableCell>

                  <TFBTableCell align="right">
                    {languagePack.debtors_report}
                  </TFBTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.paymentsData?.map((row, index) => (
                  <TableRow
                    key={"team-" + index}
                    sx={{
                      backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                    }}
                  >
                    <TFBTableCell
                      sx={sxStickyColumn}
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                      align="left"
                    >
                      <TFBTeamLabel
                        name={row.team_name}
                        color={row?.team_color ?? null}
                        onClick={() =>
                          history.push(`/club/team/${row.id_team}`)
                        }
                      />
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {row.total_players_paid}/{row.total_players}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {row.total_paid > 0
                        ? Number(row.total_paid).toLocaleString()
                        : "-"}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {row.total_players_to_pay > 0
                        ? Number(row.total_players_to_pay).toLocaleString()
                        : "-"}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {row.payment_percent <= 100
                        ? row.payment_percent + " %"
                        : "-"}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      <div
                        className="actions-btns-container"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <a
                          style={{
                            width: "45%",
                          }}
                          target="blank"
                          href={baseUrl(
                            `finances/get_unpaid_subscriptions?id_club=${currentUser.id_club}&year=${state.year}&month=${state.month}&id_team=${row.id_team}`
                          )}
                        >
                          <TFBIconButton name="arrow-down" />
                        </a>
                      </div>
                    </TFBTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </TFBCardBody>
    </TFBCard>
  );
}
export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(PerTeamReceiptsStats);
