import React, { useEffect, useState } from "react";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import { connect } from "react-redux";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBDropdown from "../../../components/design-system/Dropdown/TFBDropdown";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import { baseUrl } from "../../../utils/utilFunctions";
import _, { find } from "lodash";

type DebtorPaymentReportPopupProps = {
  isOpen?: boolean;
  title: string;
  clubID: number;
  onCLosePopup: () => {};
  languagePack: any;
  teamsIDs: string[] | number[];
  token: any;
  id_team: any;
};

function DebtorPaymentReportPopup({
  isOpen,

  clubID,
  title,
  onCLosePopup,
  languagePack,
  token,
  id_team,
}: DebtorPaymentReportPopupProps) {
  const [year, setYear] = useState(new Date().getFullYear());

  const [month, setMonth] = useState(new Date().getMonth());

  const [selectedTeam, setSelectedTeam] = useState<any>(null);

  function handleChangeYear(e: any, value: any) {
    setYear(value.value);
  }
  function handleChangeMonth(e: any, value: any) {
    setMonth(value.value);
  }
  function handleChangeSelectedTeam(selected: any) {
    setSelectedTeam(selected);
  }

  const { data: teams, loading: isTeamsLoading } = useAxiosGet(
    `training/get_club_teams?token=${token}&all=1&type=select`
  );
  useEffect(() => {
    if (teams) {
      if (_.size(teams["list"] ?? []) > 0) setSelectedTeam(teams["list"][0]);
    }
  }, [teams]);

  useEffect(() => {
    if (teams && id_team) {
      if (_.size(teams["list"] ?? []) > 0) setSelectedTeam(find(teams["list"], (e: any) => e.value == id_team));
    }
  }, [id_team, teams])

  return (
    <TFBDialog
      scroll="body"
      title={title}
      closePopup={onCLosePopup}
      open={true}
      fullWidth={true}
      maxWidth="sm"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
          <TFBDropdown
            color="lightGray"
            name="year"
            selection={true}
            label={languagePack.year}
            options={[
              { key: 0, value: 2024, text: "2024" },
              { key: 1, value: 2023, text: "2023" },
              { key: 2, value: 2022, text: "2022" },
              { key: 3, value: 2021, text: "2021" },
            ]}
            value={year}
            //@ts-ignore e passed in onChange function
            onChange={handleChangeYear}
          ></TFBDropdown>
          <TFBDropdown
            color="lightGray"
            selection={true}
            label={languagePack.month}
            name="month"
            value={month}
            options={[
              { key: 0, value: 0, text: languagePack.month_1 },
              { key: 1, value: 1, text: languagePack.month_2 },
              { key: 2, value: 2, text: languagePack.month_3 },
              { key: 3, value: 3, text: languagePack.month_4 },
              { key: 4, value: 4, text: languagePack.month_5 },
              { key: 5, value: 5, text: languagePack.month_6 },
              { key: 6, value: 6, text: languagePack.month_7 },
              { key: 7, value: 7, text: languagePack.month_8 },
              { key: 8, value: 8, text: languagePack.month_9 },
              { key: 9, value: 9, text: languagePack.month_10 },
              { key: 10, value: 10, text: languagePack.month_11 },
              { key: 11, value: 11, text: languagePack.month_12 },
            ]}
            //@ts-ignore e passed in onChange function
            onChange={handleChangeMonth}
          ></TFBDropdown>

          <TFBSelect
            // selection={true}
            label={languagePack.team}
            name="team"
            //@ts-ignore
            value={selectedTeam}
            //@ts-ignore list does not exist on type never
            options={teams?.list}
            //@ts-ignore e passed in onChange function
            onChange={handleChangeSelectedTeam}
            style={{ component: { marginBottom: 30 } }}
            isDisabled={id_team ?? false}
          />
        </div>
        <div
          style={{
            display: "flex",

            gap: "12px",
            justifyContent: "space-between",
          }}
        >
          <TFBButton
            style={{ width: "45%" }}
            renderIcon={() => <TFBIcon name="x-large" />}
            onClick={onCLosePopup}
          >
            {languagePack.Cancel}
          </TFBButton>
          <a
            style={{
              width: "45%",
            }}
            target="blank"
            href={baseUrl(
              `finances/get_unpaid_subscriptions?id_club=${clubID}&year=${year}&month=${month +
              1}&id_team=${selectedTeam?.["value"]}`
            )}
          >
            <TFBButton
              style={{ width: "100%" }}
              color="green"
              renderIcon={() => <TFBIcon name="arrow-down" />}
            >
              {languagePack.generate_pdf_report}
            </TFBButton>
          </a>
        </div>
      </div>
    </TFBDialog>
  );
}

export default connect(({ layoutService, auth }: any) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(DebtorPaymentReportPopup);
